'use client'

import { CharacterItem } from '@/backend/models/Character'
import { CharacterGrid } from './HotGrid'

export default function GeneralGrid({
  link,
  characters,
  extra,
  title,
}: {
  link: string
  extra?: React.ReactNode
  characters: CharacterItem[]
  title: string | React.ReactNode
}) {
  const currentTitle = title
  return (
    <CharacterGrid
      title={currentTitle}
      link={link}
      characters={characters}
      extra={extra}
    />
  )
}
