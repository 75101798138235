'use client'

import { useI18n } from '@/locales/client'

export default function CategoryTitle1() {
  const t = useI18n()
  return (
    <div className="c-b2 text-text-subtlest">{t('common.genreCharacter')}</div>
  )
}
