'use client'

import CharacterCard from '@/app/_components/Shared/CharacterCard'
import { SubHeader } from '@/app/_primitives/SubHeader'
import { cn } from '@/app/utils'
import { CharacterItem } from '@/backend/models/Character'
import { useI18n } from '@/locales/client'

export function CharacterGrid({
  title,
  link,
  characters,
  extra,
  hideSubHeader,
  type,
}: {
  extra?: React.ReactNode
  title: string | React.ReactNode
  link: string
  characters: CharacterItem[]
  hideSubHeader?: boolean
  type?: string
}) {
  return (
    <div className="flex flex-col gap-24">
      {!hideSubHeader && (
        <SubHeader href={link}>
          <span className="character-title text-white">{title}</span>
        </SubHeader>
      )}

      <div
        className={
          'grid gap-16 max-lg:flex-col max-lg:items-center lg:grid-cols-2 lg:flex-wrap lg:gap-24 xl:grid-cols-3 2xl:grid-cols-4 2xl:gap-32'
        }>
        {characters.map((c, index) => {
          return (
            <CharacterCard
              character={c}
              key={c.id}
              className={cn(
                type === 'hot' && [
                  index >= 6 && 'xl:hidden 2xl:flex',
                  index >= 4 && 'max-xl:hidden',
                ],
                type === 'verified' && [index >= 3 && 'xl:hidden 2xl:flex'],
                type === 'rising' && [
                  index >= 3 && 'xl:hidden 2xl:flex',
                  index >= 2 && 'max-lg:hidden',
                ],
                type === 'latest' && [
                  index >= 3 && 'xl:hidden 2xl:flex',
                  index >= 2 && 'max-lg:hidden',
                ],
              )}
            />
          )
        })}
        {extra}
      </div>
    </div>
  )
}

export default function HotGrid({
  link,
  characters,
  extra,
  type,
  hideSubHeader,
}: {
  extra?: React.ReactNode
  link: string
  count: number
  characters: CharacterItem[]
  type?: 'hot' | 'verified' | 'rising' | 'latest'
  hideSubHeader?: boolean
}) {
  const t = useI18n()
  const getTitle = () => {
    if (type === 'hot') {
      return t('common.mainCategoryLabel1')
    }

    if (type === 'verified') {
      return t('common.verified_character')
    }

    if (type === 'rising') {
      return t('common.rising_character')
    }

    if (type === 'latest') {
      return t('common.newlyRegCharacter')
    }
    return t('common.mainCategoryLabel1')
  }
  const currentTitle = getTitle()
  return (
    <CharacterGrid
      title={currentTitle}
      link={link}
      characters={characters}
      extra={extra}
      hideSubHeader={hideSubHeader}
      type={type}
    />
  )
}
