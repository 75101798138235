'use client'

import Heart from '@/app/_components/Icon/Heart'
import Share from '@/app/_components/Icon/Share'
import { Avatar } from '@/app/_components/Shared/Avatar'
import { LikeButton } from '@/app/_components/Shared/LikeButton'
import { toast } from '@/app/_primitives/Toast'
import { CharacterItem } from '@/backend/models/Character'
import Image from 'next/image'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

type Props = {
  character: CharacterItem
  className?: string
}

export default function CharacterCard({ className = '', character }: Props) {
  return (
    <Link
      data-id={character.id}
      href={`/characters/${character.humanReadableID}`}
      className={twMerge(
        'group relative box-border flex h-447 w-full flex-col rounded-20 bg-transparent shadow-card-mobile transition-all duration-300 ease-in-out hover:-mt-10 hover:shadow-xl max-lg:w-full  lg:w-340 xl:w-296 xl:shadow-card-web 2xl:w-304 ',
        className,
      )}>
      <div
        className="relative h-281 w-full shrink-0 overflow-hidden rounded-t-22 "
        style={{ backgroundColor: character.colorSpace || '#FFFFFF' }}>
        <Image
          className="object-cover"
          src={
            character.imageURL === 'need-image'
              ? 'https://picsum.photos/300/275'
              : character.imageURL
          }
          fill
          alt={`${character.name}`}
        />
        <div className="absolute bottom-10 right-12 flex gap-4 opacity-0 transition-opacity group-hover:opacity-100">
          <LikeButton
            id={character.id}
            className=" flex size-32 items-center justify-center rounded-full border border-solid border-tertiary bg-white">
            <Heart
              className={`size-16 ${character.isLiked ? 'fill-cherry' : ''}`}
            />
          </LikeButton>
          <div
            aria-hidden
            onClick={(e) => {
              e.preventDefault()
              navigator.clipboard.writeText(
                `${document.location.host}/characters/${character.id}`,
              )
              toast.success({
                title: `${character.name} URL을 복사했습니다.`,
                content: '원하는 곳에 붙여넣기(Ctrl+V) 해주세요.',
              })
            }}
            className="flex size-32 cursor-pointer items-center justify-center rounded-full border-1 border-tertiary bg-white">
            <Share className="size-16" />
          </div>
        </div>
      </div>
      <div className="h-166 rounded-b-22 bg-white p-16">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-8">
            <div className="c-h7 line-clamp-1" title={character.name}>
              {character.name}
            </div>
            <div className="c-b4 line-clamp-3">
              {character.oneLineIntroduction}
            </div>
          </div>
          <div className="flex justify-between">
            <Avatar
              userInfo={character.user}
              href={`/u/${character.user.id}`}
            />
          </div>
        </div>
      </div>
    </Link>
  )
}
